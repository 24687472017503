<!--
 * @Author: 侯怀烨
 * @Date: 2020-12-31 14:22:55
 * @LastEditTime: 2021-03-26 17:10:07
 * @LastEditors: Please set LastEditors
 * @Description: 添加入库
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\inquire\components\AddWarehousing.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :title="title"
      center
      :visible.sync="showAdd"
      width="1100px"
    >
      <div>
        <el-row>
          <el-col :span="16">
            <el-form ref="formlistr" inline :model="form" :rules="rulesd">
              <el-form-item label="入库类型：" prop="bill_type">
                <el-select
                  v-model="form.bill_type"
                  :disabled="typeid != 0"
                  style="width: 120px"
                >
                  <el-option
                    v-for="(item, index) in Typesof"
                    :key="index"
                    :label="item.type_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入库仓库：" prop="depot_id">
                <el-select
                  v-model="form.depot_id"
                  :disabled="typeid != 0"
                  style="width: 120px"
                >
                  <el-option
                    v-for="(item, index) in warehouse"
                    :key="index"
                    :label="item.depot_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品条码：">
                <el-input
                  :disabled="typeid != 0"
                  placeholder="扫码枪扫码添加商品"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <!-- 添加入库单 -->
        <el-row v-show="typeid == 0">
          <el-table stripe :data="tableData" height="360px">
            <el-table-column type="index" width="60"></el-table-column>
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            >
              <template
                v-if="list.label == '商品名称'"
                #default="{ $index, row }"
              >
                <goods-search
                  :f-key="row.goods_name"
                  @add-rows="addRows"
                  @select-goods-all="selectGoods($event, row, $index)"
                ></goods-search>
              </template>
              <template v-else-if="list.label == '生产日期'" #default="{ row }">
                <el-date-picker
                  v-model="row.state_time"
                  style="width: 140px"
                  type="date"
                  :disabled="typeid != 0"
                  placeholder="选择日期"
                ></el-date-picker>
              </template>
              <template v-else-if="list.label == '入库数量'" #default="{ row }">
                <el-input
                  v-model="row.quantity"
                  :disabled="typeid != 0"
                  style="width: 80px"
                />
              </template>
              <template v-else-if="list.label == '单位'" #default="{ row }">
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 70px"
                  @change="unitChange($event, row)"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="list.label == '金额'" #default="{ row }">
                {{ Number(row.quantity * row.goods_price).toFixed(2) }}
              </template>
              <template v-else-if="list.label == '单价'" #default="{ row }">
                <el-input
                  v-model="row.goods_price"
                  :disabled="typeid != 0"
                  style="width: 80px"
                  onkeyup="value=value.replace(/[^\d.]/g,'') "
                />
              </template>
              <template v-else #default="{ row }">
                {{ row[list.prop] }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              fixed="right"
              width="120"
            >
              <template #default="{ row, $index }">
                <el-button type="text" @click="handlerAdd(row, $index)">
                  添加
                </el-button>
                <el-button type="text" @click="handlerDelet(row, $index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-table v-show="typeid != 0" stripe :data="danjutable">
            <el-table-column
              v-for="(list, index) in colemds"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            >
              <template v-if="list.label == '实际入库'" #default="{ row }">
                <el-input
                  v-model="row.goodsNum"
                  style="width: 80px"
                  :disabled="typeid != 0"
                  onkeyup="value=value.replace(/[^\d.]/g,'') "
                />
              </template>
              <template v-else-if="list.label == '单价'" #default="{ row }">
                <el-input
                  v-model="row.goodsPrice"
                  style="width: 80px"
                  :disabled="typeid != 0"
                  onkeyup="value=value.replace(/[^\d.]/g,'') "
                />
              </template>
              <template v-else-if="list.label == '生产日期'" #default="{ row }">
                <el-date-picker
                  v-model="row.goodsDate"
                  style="width: 140px"
                  :disabled="typeid != 0"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </template>
              <template v-else-if="list.label == '备注'" #default="{ row }">
                <el-input
                  v-model="row.remark"
                  :disabled="typeid != 0"
                  placeholder="备注"
                ></el-input>
              </template>
              <template v-else-if="list.label == '金额'" #default="{ row }">
                {{ Number(row.goodsNum * row.goodsPrice).toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <span></span>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="typeid == 0"
          v-show="typeid == 1 && row.bill_status != 2 && row.bill_status != 5"
          type="primary"
          @click="handlercreate"
        >
          审 核
        </el-button>
        <el-button
          v-if="typeid == 0"
          v-show="typeid == 1 && row.bill_status != 5"
          type="primary"
          @click="handlerpause"
        >
          作 废
        </el-button>
        <el-button
          v-if="typeid == 0"
          v-show="typeid == 0"
          type="primary"
          @click="handlersave"
        >
          提 交
        </el-button>
        <el-button @click="showAdd = !showAdd">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import _ from 'lodash'
  import {
    getAdvanceOrderEditTableList,
    getAdvanceOrderClientSelectList,
    goodsSelect,
    editRow,
    driverList,
    getAllSelect,
  } from '@/api/advanceOrder'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      GoodsSearch,
    },
    data() {
      return {
        showAdd: false,
        form: {
          depot_id: '',
          bill_type: '',
          bill_class: 2,
          goods: [],
        },
        rulesd: {
          bill_type: [
            { required: true, message: '请选择入库类型', trigger: 'change' },
          ],
          depot_id: [
            { required: true, message: '请选择入库仓库', trigger: 'change' },
          ],
        },
        tableData: [
          {
            activity_count: '',
            activity_id: '',
            arr_goods_type: [],
            arr_unit: [],
            balance_count: '',
            brand_id: '',
            brand_name: '',
            class_name: '',
            depot_stock_num: '',
            goodsDate: '',
            goodsId: '',
            goodsName: '',
            goodsNum: '',
            goodsPrice: '',
            goodsSpec: '',
            goodsUnit: '',
            goods_child_id: '',
            goods_id: '1156',
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            id: '1156',
            info: '',
            is_close_text: '',
            is_img: '',
            quantity: '',
            sale_type_text: '',
            specs: '',
            unitID: '',
            unit_cv: '',
            unit_id: '',
            unit_name: '',
          },
        ],
        checkList: [
          '商品名称',
          '单位',
          '入库数量',
          '单价',
          '金额',
          '生产日期',
          '备注',
        ],
        colemd: [
          {
            order: 1,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '80',
          },
          {
            order: 3,
            label: '入库数量',
            align: 'center',
            prop: 'quantity',
            width: '100',
          },
          {
            order: 4,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '120',
          },
          {
            order: 5,
            label: '金额',
            align: 'center',
            prop: 'goods_money',
            width: '120',
          },
          {
            order: 6,
            label: '生产日期',
            align: 'center',
            prop: 'goods_production_date',
            width: '170',
          },
          {
            order: 7,
            label: '备注',
            align: 'center',
            prop: 'info',
            width: '120',
          },
        ],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Typesof: '/baseAdmin/common/bill-in-type',
          save: '/depotAdmin/in-out/add',
          data: '/depotAdmin/in-out/detail',
          shenhe: '/depotAdmin/in-out/check',
          zuofei: '/depotAdmin/in-out/scrap',
        },
        Typesof: [],
        warehouse: [],
        danjutable: [],
        row: {},
        bill_id: '',
        colemds: [
          {
            order: 1,
            label: '商品名称',
            align: 'center',
            prop: 'goodsName',
            width: '220',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'goodsUnit',
            width: '80',
          },
          {
            order: 3,
            label: '申请数量',
            align: 'center',
            prop: 'apply_goods_num',
            width: '100',
          },
          {
            order: 4,
            label: '实际入库',
            align: 'center',
            prop: 'goodsNum',
            width: '120',
          },
          {
            order: 5,
            label: '单价',
            align: 'center',
            prop: 'goodsPrice',
            width: '120',
          },
          {
            order: 6,
            label: '金额',
            align: 'center',
            prop: '',
            width: '150',
          },
          {
            order: 7,
            label: '生产日期',
            align: 'center',
            prop: 'goodsDate',
            width: '120',
          },
          {
            order: 8,
            label: '备注',
            align: 'center',
            prop: '',
            width: '120',
          },
        ],
        typeid: 0,
        title: '',
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    mounted() {
      this.handlerTypesof()
      this.handlerwarehouse()
    },
    methods: {
      // 状态
      type(type) {
        this.typeid = type
        type == 0 ? (this.title = '添加入库单') : (this.title = '入库单')
      },
      // 作废
      handlerpause() {
        postAction(this.url.zuofei, { bill_id: this.bill_id })
          .then((res) => {
            console.log(res, '')
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'success',
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //审核
      handlercreate() {
        this.danjutable.forEach((item) => {
          delete item.apply_goods_num
        })
        postAction(this.url.shenhe, {
          bill_id: this.bill_id,
          goods: JSON.stringify(this.danjutable),
        })
          .then((res) => {
            console.log(res, '')
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'success',
              })
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlercreateData(row) {
        this.row = row
        this.danjutable = []
        postAction(this.url.data, { bill_id: row.url_id })
          .then((res) => {
            console.log(res)
            this.bill_id = res.data.info.bill_id
            this.form.depot_id = res.data.info.depot_id
            this.form.bill_type = res.data.info.bill_type
            res.data.goods.forEach((item) => {
              var a = {
                id: item.id,
                goodsId: item.goods_id,
                goodsName: item.goods_name,
                apply_goods_num: item.apply_goods_num,
                goodsNum: '',
                goodsPrice: item.goods_price,
                goodsSpec: item.goods_spec,
                goodsUnit: item.goods_unit,
                unitID: item.unit_id,
                goodsDate: '',
              }
              this.danjutable.push(a)
              console.log(this.danjutable, '')
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //保存
      handlerwrite() {},
      //提交入库单据
      handlersave() {
        this.$refs['formlistr'].validate((valid) => {
          console.log(valid, '')
          if (valid) {
            var b = []
            this.tableData.forEach((item) => {
              var a = {
                goodsId: item.goods_id,
                goodsName: item.goods_name,
                goodsNum: item.quantity,
                goodsPrice: item.goods_price,
                goodsSpec: item.goodsSpec,
                goodsUnit: item.unit_name,
                unitID: item.unit_id,
                goodsDate: item.goods_production_date,
              }
              b.push(a)
            })
            this.form.goods = JSON.stringify(b)
            postAction(this.url.save, this.form)
              .then((res) => {
                console.log(res, '')
                this.showAdd = false
                this.$emit('handlershuxin')
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
              })
              .catch((err) => {
                console.log(err, '')
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      // 添加
      handlerAdd(row, index) {
        this.tableData.splice(index + 1, 0, JSON.parse(JSON.stringify(row)))
      },
      // 删除
      handlerDelet(row, index) {
        if (this.tableData.length <= 1) {
        } else {
          this.tableData.splice(index, 1)
        }
      },
      //类型
      handlerTypesof() {
        postAction(this.url.Typesof, {})
          .then((res) => {
            console.log(res, '入库类型')
            this.Typesof = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //  仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      selectGoods(event, row, index) {
        console.log(event, row, '1')
        Object.assign(row, event)
      },

      addRows(val) {
        console.log('添加rows', val)
        nsole.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.goodsName = item.goods_name
            this.tableData.splice(this.tableData.length - 1, 0, item)
          })
          this.$message.success('添加成功')
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        row.goods_price = price
        row.unit_name = name
      },
    },
  }
</script>

<style></style>
