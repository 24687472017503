var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.title,
            center: "",
            visible: _vm.showAdd,
            width: "1100px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAdd = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "formlistr",
                          attrs: {
                            inline: "",
                            model: _vm.form,
                            rules: _vm.rulesd,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库类型：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: { disabled: _vm.typeid != 0 },
                                  model: {
                                    value: _vm.form.bill_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "bill_type", $$v)
                                    },
                                    expression: "form.bill_type",
                                  },
                                },
                                _vm._l(_vm.Typesof, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.type_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库仓库：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: { disabled: _vm.typeid != 0 },
                                  model: {
                                    value: _vm.form.depot_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "depot_id", $$v)
                                    },
                                    expression: "form.depot_id",
                                  },
                                },
                                _vm._l(_vm.warehouse, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.depot_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品条码：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.typeid != 0,
                                  placeholder: "扫码枪扫码添加商品",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.typeid == 0,
                      expression: "typeid == 0",
                    },
                  ],
                },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        stripe: "",
                        data: _vm.tableData,
                        "show-summary": "",
                        height: "360px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "60" },
                      }),
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              list.label == "商品名称"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var $index = ref.$index
                                      return [
                                        _c("goods-search", {
                                          attrs: {
                                            "f-key": _vm.row.goods_name,
                                          },
                                          on: {
                                            "add-rows": _vm.addRows,
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                _vm.row,
                                                $index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "生产日期"
                                ? {
                                    key: "default",
                                    fn: function (undefined) {
                                      return [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "140px" },
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期",
                                          },
                                          model: {
                                            value: _vm.row.state_time,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.row,
                                                "state_time",
                                                $$v
                                              )
                                            },
                                            expression: "row.state_time",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "出库数量"
                                ? {
                                    key: "default",
                                    fn: function (undefined) {
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value: _vm.row.quantity,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.row, "quantity", $$v)
                                            },
                                            expression: "row.quantity",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "单位"
                                ? {
                                    key: "default",
                                    fn: function (undefined) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "70px" },
                                            attrs: { placeholder: "选择单位" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange(
                                                  $event,
                                                  _vm.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.row,
                                                  "unit_id",
                                                  $$v
                                                )
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.row.arr_unit,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.unit_name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : list.label == "金额"
                                ? {
                                    key: "default",
                                    fn: function (undefined) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Number(
                                                _vm.row.quantity *
                                                  _vm.row.goods_price
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  }
                                : list.label == "单价"
                                ? {
                                    key: "default",
                                    fn: function (undefined) {
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            disabled: _vm.typeid != 0,
                                            onkeyup:
                                              "value=value.replace(/[^\\d.]/g,'') ",
                                          },
                                          model: {
                                            value: _vm.row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.row,
                                                "goods_price",
                                                $$v
                                              )
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : {
                                    key: "default",
                                    fn: function (undefined) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(_vm.row[list.prop]) + " "
                                        ),
                                      ]
                                    },
                                  },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          fixed: "right",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerAdd(_vm.row, $index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerDelet(_vm.row, $index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.typeid != 0,
                          expression: "typeid != 0",
                        },
                      ],
                      attrs: { stripe: "", data: _vm.danjutable },
                    },
                    _vm._l(_vm.colemds, function (list, index) {
                      return _c(
                        "el-table-column",
                        {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                        },
                        [
                          list.label == "实际出库"
                            ? [
                                _c("el-input", {
                                  staticStyle: { width: "80px" },
                                  attrs: { disabled: _vm.typeid != 0 },
                                  model: {
                                    value: _vm.row.goodsNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.row, "goodsNum", $$v)
                                    },
                                    expression: "row.goodsNum",
                                  },
                                }),
                              ]
                            : list.label == "单价"
                            ? [
                                _c("el-input", {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    disabled: _vm.typeid != 0,
                                    onkeyup:
                                      "value=value.replace(/[^\\d.]/g,'') ",
                                  },
                                  model: {
                                    value: _vm.row.goodsPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.row, "goodsPrice", $$v)
                                    },
                                    expression: "row.goodsPrice",
                                  },
                                }),
                              ]
                            : list.label == "生产日期"
                            ? [
                                _c("el-date-picker", {
                                  staticStyle: { width: "140px" },
                                  attrs: {
                                    disabled: _vm.typeid != 0,
                                    type: "date",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.row.goodsDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.row, "goodsDate", $$v)
                                    },
                                    expression: "row.goodsDate",
                                  },
                                }),
                              ]
                            : list.label == "金额"
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      Number(
                                        _vm.row.goodsNum * _vm.row.goodsPrice
                                      ).toFixed(2)
                                    ) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("el-row", [_c("span")]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.typeid == 0
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.typeid == 1 &&
                            _vm.row.bill_status != 2 &&
                            _vm.row.bill_status != 5,
                          expression:
                            "typeid == 1 && row.bill_status != 2 && row.bill_status != 5",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handlercreate },
                    },
                    [_vm._v(" 审 核 ")]
                  )
                : _vm._e(),
              _vm.typeid == 0
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.typeid == 1 && _vm.row.bill_status != 5,
                          expression: "typeid == 1 && row.bill_status != 5",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerpause },
                    },
                    [_vm._v(" 作 废 ")]
                  )
                : _vm._e(),
              _vm.typeid == 0
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.typeid == 0,
                          expression: "typeid == 0",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersave },
                    },
                    [_vm._v(" 提 交 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAdd = !_vm.showAdd
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }